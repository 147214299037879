<template>
  <div style="height: 20px; padding-bottom: 8px; padding-top: 8px;">
    <div style="border-top: 2px solid; border-bottom: 2px solid; border-top-color: hsl(204, 86%, 53%); border-bottom-color: hsl(204, 86%, 53%);"></div>
  </div>
</template>

<script>
export default {
  name: "HorizontalDivider"
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css";
</style>