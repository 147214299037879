<template>
  <!-- Favicon -->
  <link rel="icon" href="@/assets/images/favicon.ico">

  <!--- DevIcons --->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.14.0/devicon.min.css">

  <div class="has-background-black-ter" style="width: 100%; height: 100%; left: 0; top: 0; z-index: 10;">
    <!-- Introduction -->
    <div>
      <!-- Header -->
      <div id="header" class="block" style="height: 64px; padding: 32px; margin: 0">
        <!-- GitHub -->
        <div>
          <a href="https://github.com/vini2003">
            <img class="social" src="@/assets/images/logos/github_logo.svg" alt="GitHub" style="width: 64px; height: 64px; float: right; clear: right; margin: 16px;"/>
          </a>
          <a href="https://discord.gg/zgMkMbvG9q">
            <img class="social" src="@/assets/images/logos/discord_logo.svg" alt="Discord" style="width: 64px; height: 64px; float: right; clear: right; margin: 16px;">
          </a>
          <a href="https://twitter.com/vini2003_dev">
            <img class="social" src="@/assets/images/logos/twitter_logo.svg" alt="Twitter" style="width: 64px; height: auto; float: right; clear: right; margin: 16px;"/>
          </a>
        </div>
      </div>

      <!-- Introduction -->
      <section id="introduction" class="section is-small">
        <div class="container is-vcentered introduction-container-extra" style="padding: 16px;">
          <h1 class="title title-extra has-text-centered">
            Hi, I'm
            <span class="has-text-info">vini2003</span>
            !
          </h1>
          <h1 class="subtitle subtitle-extra has-text-centered">
            I'm
            <span class="has-text-info">{{ getLifeTime() }}</span>
            years old, with
            <span class="has-text-info has-text-weight-bold">{{ getExperienceTime() }}</span>
            years of experience with Java and Kotlin development.
          </h1>
          
          <HorizontalDivider></HorizontalDivider>

          <!-- Introduction --->
          <h2 class="subtitle subtitle-extra" style="padding: 8px; margin: 8px;">
            While my interest in programming started all the way back in
            <span class="has-text-info has-text-weight-bold">2010</span>,
            it was in
            <span class="has-text-info has-text-weight-bold">2019</span>
            that I started developing mods for the
            <a class="has-text-link" href="https://fabricmc.net">Fabric</a>
            toolchain for
            <a class="has-text-link" href="https://minecraft.net/">Minecraft</a>
            , and officially started my career.
          </h2>

          <HorizontalDivider></HorizontalDivider>

          <!-- Experience --->
          <div>
            <div class="container" style="padding: 8px; margin: 8px;">
              <div class="columns">
                <div class="column" style="min-width: 50%; padding: 8px; margin: 8px;">
                  <h2 class="subtitle subtitle-extra" style="padding: 8px; margin: 8px;">
                    Accumulated experience in many domains allows me to provide you with speed, reliability, and ease of use in back-end and game systems.
                  </h2>
                </div>
                <div class="column" style="padding: 8px; margin: 8px;">
                  <div class="columns is-centered">
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/Java_(programming_language)">
                        <img src="@/assets/images/logos//java_logo.svg" alt="Java"/>
                      </a>
                    </div>
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/Kotlin_(programming_language)">
                        <img src="@/assets/images/logos//kotlin_logo.svg" alt="Kotlin"/>
                      </a>
                    </div>
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/JavaScript">
                        <img src="@/assets/images/logos//javascript_logo.svg" alt="JavaScript"/>
                      </a>
                    </div>
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/TypeScript">
                        <img src="@/assets/images/logos//typescript_logo.svg" alt="TypeScript"/>
                      </a>
                    </div>
                  </div>
                  <div class="columns is-centered">
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/Vue.js">
                        <img src="@/assets/images/logos//vuejs_logo.svg" alt="Vue.js"/>
                      </a>
                    </div>
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/Node.js">
                        <img src="@/assets/images/logos//nodejs_logo.svg" alt="Node.js"/>
                      </a>
                    </div>
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/Docker_(software)">
                        <img src="@/assets/images/logos//docker_logo.svg" alt="Docker"/>
                      </a>
                    </div>
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/NATS_Messaging">
                        <img src="@/assets/images/logos//natsio_logo.svg" alt="Nats.io"/>
                      </a>
                    </div>
                  </div>
                  <div class="columns is-centered">
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/C_Sharp_(programming_language)">
                        <img src="@/assets/images/logos//csharp_logo.svg" alt="C#"/>
                      </a>
                    </div>
                    <div class="column domain-showcase">
                      <a href="https://en.wikipedia.org/wiki/Unity_(game_engine)">
                        <img src="@/assets/images/logos//unity_logo.svg" alt="Unity"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <HorizontalDivider></HorizontalDivider>

          <!-- Experience --->
          <div>
            <div class="container" style="padding: 8px; margin: 8px;">
              <div class="columns">
                <div class="column" style="min-width: 50%;">
                  <img src="@/assets/images/showcases/showcase_1.png" id="showcase" style="border-radius: 8px; min-width: 640px; min-height: 360px;">
                </div>
                <div class="column">
                  <h2 class="subtitle subtitle-extra">
                    Years of Minecraft development allow me to quickly plan out and develop new ideas, allowing for fast turn-around times.
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <HorizontalDivider></HorizontalDivider>

          <div class="container is-vcentered introduction-container-extra" style="padding: 16px;">
            <h1 class="title title-extra" style="text-align: center">
              Interested?
            </h1>
            <h1 class="subtitle subtitle-extra" style="text-align: center">
              Contact me at
              <a href="mailto: contact@vini2003.dev">contact@vini2003.dev</a>
              - let's work something out!
            </h1>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import HorizontalDivider from "./components/HorizontalDivider.vue";

export default {
  name: "App",
  components: {
    HorizontalDivider
  },
  computed: {
    projects: function () {
      const data = [];

      const entries = require("@/assets/projects/entries.json");

      entries.entries.forEach((entry) => {
        const entryFile = require(`@/assets/projects/${entry}.json`);

        data.push(entryFile);
      });

      return data;
    },
  },
  created: () => {
    document.title = "vini2003"

    setInterval(() => {
      let image = document.getElementById("showcase");
      let random = Math.floor(Math.random() * 20) + 1;
      image.src = require("@/assets/images/showcases/showcase_" + random + ".png")
      console.log(image.src)
    }, 5000)
  },
  methods: {
    getLifeTime() {
      return Math.floor((new Date() - new Date("2003-04-23").getTime()) / 3.15576e+10 * 10) / 10
    },
    getExperienceTime() {
      return Math.round((new Date() - new Date("2019-06-01").getTime()) / 3.15576e+10 * 10) / 10
    },
    getDevIconSvg(path) {
      let url = "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/" + path
      require(url)
      return url
    }
  }
};
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css";

.domain-showcase {
  border-radius: 8px;

  max-width: 96px;
  max-height: 96px;

  margin: 8px;

  background-color: hsl(0, 0%, 21%);

  transition: 0.25s;
}

.domain-showcase:hover {
  background-color: hsl(204, 86%, 53%);

  transform: scale(1.2);
}

.social {
  transition: 0.25s;
}

.social:hover {
  transform: scale(1.2);
}

.title {
  font-family: comfortaa, serif !important;
  font-size: xxx-large;

  text-shadow: 1px 1px black;

  color: white;
}

.subtitle {
  font-family: comfortaa, serif !important;

  text-shadow: 1px 1px black;

  color: white;
}

@font-face {
  font-family: "comfortaa";
  src: url("./assets/fonts/Comfortaa-Regular.woff") format("woff");
}

@font-face {
  font-family: "comfortaa";
  src: url("./assets/fonts/Comfortaa-Bold.woff") format("woff");
  font-weight: bold;
}

</style>